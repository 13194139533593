import React, { Component, Fragment } from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { Home, ArrowLeft } from 'react-feather'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Card, Image, Button, ButtonToolbar, Modal, Form } from 'react-bootstrap'

class OurDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
      avatar: null,
      prefix: null,
      name: null,
      specialization: null,
      slug: null,
      practices: [],
      facility_slug: '',
      clinic_slug: ''
    }
  }

  handleClose = () => {
    this.setState({ isModal: false })
  }

  handleShow = () => {
    this.setState({ isModal: true })
  }

  handleChange = (event) => {
    this.setState({ clinic_slug: event.target.value })
  }


  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const { state } = this.props.location
      if(state !== undefined) {
        const {  avatar, prefix, name, specialization, slug, practices, facility_slug } = state
        this.setState({
          avatar: avatar || null,
          prefix: prefix || null,
          name: name || null,
          specialization: specialization || null,
          slug: slug || null,
          practices: practices || [],
          facility_slug: facility_slug || '',
      })
    }
  }

  render() {
    const { topNav, accreditedHmos } = this.props.pageContext
    const { favicon } = topNav
    const { isModal, clinic_slug, avatar, prefix, name, specialization, slug, practices, facility_slug } = this.state
    const clinic = !practices || practices.length < 0 ? [] : practices.filter(e => e.facility_slug === facility_slug)
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    const defaultOffset = "+0800"

    return (
      <Fragment>
        <SEO title={"Doctor - " + prefix + " "+ name} favicon={favicon}/>
        <Layout activeNav='our-doctors' data={topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/our-doctors">
                Our Doctors
              </Breadcrumb.Item>
              <Breadcrumb.Item active className="text-capitalize">{prefix}&nbsp;{name}</Breadcrumb.Item>
            </Breadcrumb>

            <Card className="my-3">
              <Card.Body className="d-flex flex-row align-items-center border-bottom">
                <Link to="/our-doctors">
                  <ArrowLeft className="text-violet mr-2" size={14} />
                </Link>
                <p className="font-weight-bold mb-0">List of Doctors</p>
              </Card.Body>
              <Card.Body>
                <Row>
                  <Col lg={4} md={12} className="d-flex flex-column align-items-center justify-contents-center my-5">
                    <Image
                      alt="doctor-avatar"
                      className="rounded-circle avatar-mmd mb-3"
                      src={avatar || "https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"}
                      onError={(e) => { e.target.onerror = null; e.target.src = "https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg" }}
                    />
                    <h6 className="font-weight-bold text-capitalize">{prefix}&nbsp;{name}</h6>
                    <p className="text-muted text-capitalize">{specialization}</p>
                    <Button
                      variant="primary"
                      className="text-nowrap w-46"
                      onClick={this.handleShow}
                    >
                      BOOK
                    </Button>
                  </Col>
                  <Col lg={8} md={12} className="my-5">
                    {/*<h6>Department details</h6>
                    <p className="text-muted mb-2">Department of Dental Medicine</p>
                    <p className="text-muted mb-5">Room 304</p>*/}

                    <h6>Clinic schedule</h6>

                    {practices && practices.length > 0
                      ? practices.map((e, i) => {
                        if (e.practice_active && e.clinic_active && e.facility_slug === "pldt") {
                          return (
                            <Fragment key={i}>
                              {e.schedules.length !== 0
                                ? <Fragment><p className="text-muted mb-1">Name: <b className="text-dark ml-3">{e.clinic_name ? e.clinic_name : "Not Available"}</b></p>
                                  <p className="text-muted mb-1">Room No.: <b className="text-dark ml-3">{e.clinic_room ? e.clinic_room : "Not Available"}</b></p>
                                  <p className="text-muted mb-3">Local No.: <b className="text-dark ml-3">{e.clinic_local_no ? e.clinic_local_no : "Not Available"}</b></p>
                                </Fragment>
                                : null}
                              {days.map((item, i) => {
                                if (e.schedules !== undefined && e.schedules.find(elem => elem.day === item)) {
                                  return (<Row className="border-bottom py-1 mb-3" key={i}>
                                    <Col lg={6} sm={6}><p className="text-muted my-2 text-capitalize">{item}</p></Col>
                                    <Col lg={6} sm={6}>
                                      {e.schedules.map((sched, i) => sched.active && sched.day === item
                                        ? <Col lg={12} sm={12} key={i}>
                                          <p className="text-muted my-2">
                                            {sched && sched.start_time && sched.end_time? 
                                              moment(sched.start_time).utcOffset(topNav.timezone || defaultOffset).format('LT') + " - " +  moment(sched.end_time).utcOffset(topNav.timezone || defaultOffset).format('LT')                         
                                            : null}
                                          </p>
                                        </Col>
                                        : null)}
                                    </Col>
                                  </Row>)
                                } return null
                              })}
                            </Fragment>
                          )
                        }
                      }) : "Not Available"}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>

          <HMOSlider data={accreditedHmos} />
        </Layout>
        <Modal show={isModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Choose a clinic</Modal.Title>
          </Modal.Header>
          <Modal.Body className="clinic-modal">
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Clinic Name<span className="text-danger">*</span></Form.Label>
                <Form.Control as="select" className="selectpicker show-menu-arrow" onChange={event => this.handleChange(event)}>
                  <option defaultValue="clinic" disabled selected hidden>Choose a clinic</option>
                  {clinic.map((item, i) => <option key={i} value={item.clinic_slug}>{item.clinic_name}</option>)}
                </Form.Control>
              </Form.Group>
              <ButtonToolbar className="justify-content-end w-100 mt-3">
                <Button variant="light" className="border mr-3" onClick={this.handleClose}>Cancel</Button>
                <a
                  href={process.env.SYD_URL + "/book-appointment/" + slug + "/" + clinic_slug}
                  className={clinic_slug === '' ? 'btn btn-primary disabled' : "btn btn-primary"}
                >
                  PROCEED TO BOOKING
                </a>
              </ButtonToolbar>
            </Form>
          </Modal.Body>
        </Modal>
      </Fragment >
    )
  }
}

export default OurDoctor
